<template>
  <div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-header">
            <h5 class="card-title">Your Partners</h5>
          </div>

          <b-table striped hover :items="partnerList" :fields="fields">
            <template #[`cell(index)`]="data">
              {{ data.index + 1 }}
            </template>
            <template #[`cell(id)`]="data">
              {{ data.item.id }}
            </template>
            <template #[`cell(name)`]="data">
              {{ data.item.seller_company.business_profile.company_name }}
            </template>
            <template #[`cell(contact)`]="data">
              {{ data.item.seller_company.business_profile.email }}
            </template>
            <template #[`cell(date)`]="data">
              {{ data.item.updated_at | date_format }}
            </template>
            <template #[`cell(status)`]="data">
              <template v-if="data.item.crm != null">{{
                data.item.crm.partner_status
              }}</template>
            </template>
            <template #[`cell(actions)`]="data">
              <!-- <a :data-id="data.item.id" href="#deleteEmployeeModal" class="deny" data-toggle="modal"><i class="material-icons" data-toggle="tooltip" title="Deny">&#xe5c9;</i></a>
            <a href="#editEmployeeModal" class="edit" data-toggle="modal"><i class="material-icons" data-toggle="tooltip" title="Edit">&#xE254;</i></a>
            <a href="#deleteEmployeeModal" class="approve" data-toggle="modal"><i class="material-icons" data-toggle="tooltip" title="Approve">&#xe174;</i></a> -->
              <v-btn
                class="ma-2"
                tile
                outlined
                color="success"
                @click="
                  $router.push(
                    '/crm/partner-detail/' + data.item.seller_company.id
                  )
                "
              >
                View Dealer
              </v-btn>
              <v-btn
                class="ma-2"
                tile
                outlined
                color="success"
                @click="applicationDelete(data.item.crm.id, data.item.id)"
              >
                Delete
              </v-btn>
              <!-- <v-btn class="ma-2 ml-3" tile outlined color="success" @click="deny(data.item.id)">
                Deny
            </v-btn> -->
            </template>
          </b-table>
          <div class="row mt-4">
            <b-pagination
              v-model="currentPage"
              :total-rows="partnerCount"
              :per-page="limit"
              style="margin: 0 auto;"
            ></b-pagination>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12"></div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import { mapActions } from 'vuex'
import Swal from 'sweetalert2'
import GetPartnerList from '@/graphql/queries/GetPartnerList.gql'
import DeleteCrm from '@/graphql/mutations/DeleteCrm.gql'
import DeleteApplication from '@/graphql/mutations/DeleteApplication.gql'
export default {
  filters: {
    date_format: function(value) {
      if (value) {
        return moment(String(value)).format('MM/DD/YYYY')
      }
    },
  },
  data() {
    return {
      search: '',
      location: '',
      companyType: '',
      vrating: 0,
      partnerList: [],
      limit: 10,
      currentPage: 1,
      partnerCount: 0,
      fields: [
        {
          key: 'index',
          label: '#',
        },
        {
          key: 'name',
          label: 'Name',
        },
        {
          key: 'contact',
          label: 'Contact',
        },
        {
          key: 'date',
          label: 'Date of Application',
        },
        {
          key: 'status',
          label: 'Status',
        },
        {
          key: 'actions',
          label: 'Actions',
        },
      ],
    }
  },
  apollo: {
    partnerList: {
      query: GetPartnerList,
      variables() {
        return {
          supplier_company: this.$store.state.auth.user.company_relation,
          limit: this.limit,
          start: (this.currentPage - 1) * this.limit,
        }
      },
      skip() {
        return (
          !this.$store.state.auth.user ||
          this.$store.state.auth.user.company_relation === null ||
          this.$store.state.auth.user.company_relation === undefined
        )
      },
    },
  },

  mounted() {
    setTimeout(() => {
      this.getPartnerCount()
    }, 1000)
  },
  methods: {
    ...mapActions(['getApplicationTotalCount']),
    getPartnerCount() {
      this.getApplicationTotalCount({
        params:
          'status=approved&supplier_company=' +
          this.$store.state.auth.user.company_relation,
      }).then((res) => {
        this.partnerCount = res.data
      })
    },
    applicationDelete(crmId, applicationId) {
      this.$bvModal
        .msgBoxConfirm('Are you sure?', {
          title: 'Confirm',
          size: 'sm',
          buttonSize: 'md',
          okVariant: 'danger',
          okTitle: 'YES',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true,
        })
        .then(async (value) => {
          if (value == true) {
            //async ecom data
            await this.$apollo.mutate({
              mutation: DeleteApplication,
              variables: {
                id: applicationId,
              },
            })

            await this.$apollo.mutate({
              mutation: DeleteCrm,
              variables: {
                id: crmId,
              },
            })
            Swal.fire({
              title: '',
              text: 'The application has been successfully deleted!',
              icon: 'success',
              confirmButtonClass: 'btn btn-secondary',
            })
            await this.$apollo.queries.partnerList.refetch()
          }
        })
    },
  },
}
</script>
