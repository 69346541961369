<template>
  <div>
    <div class="section-shaped my-0 skew-separator skew-mini ">
      <v-img
        class="page-header header-filter  header-small "
        max-height="390px"
        lazy-src="/media/biz-header.jpg"
        src="/media/header2.jpg"
        gradient="to top right, rgba(0,0,0,.53), rgba(25,32,72,.7)"
      >
        <div class="container " style="padding-bottom: 65px;">
          <div class="md-layout justify-md-center ">
            <div
              class="md-layout-item md-size-66 md-small-size-100 mt-35 text-center"
            >
              <div class="lato-header  font-weight-bolder text-white">
                Your Partners
              </div>
              <div class="mt-5">
                <h4 class="text-white "></h4>
              </div>
            </div>
          </div>
        </div>
      </v-img>
    </div>

    <div class="main main-raised" style="background:#fff;">
      <div class="section">
        <div class="container">


<div class="row">
            <div class="col-lg-4">

            </div>
              <div class="col-lg-8">

            </div>
</div>






          <!-- <div class="row">
            <div class="col-xl-4">
              <base-material-chart-card
                :data="dataCompletedTasksChart.data"
                :options="dataCompletedTasksChart.options"
                hover-reveal
                color="info"
                type="Line"
              >
                <template #reveal-actions>
                  <v-tooltip bottom>
                    <template #activator="{ attrs, on }">
                      <v-btn v-bind="attrs" color="info" icon v-on="on">
                        <v-icon color="info">
                          mdi-refresh
                        </v-icon>
                      </v-btn>
                    </template>

                    <span>Refresh</span>
                  </v-tooltip>

                  <v-tooltip bottom>
                    <template #activator="{ attrs, on }">
                      <v-btn v-bind="attrs" light icon v-on="on">
                        <v-icon>mdi-pencil</v-icon>
                      </v-btn>
                    </template>

                    <span>Change Date</span>
                  </v-tooltip>
                </template>

                <div id="kt_mixed_widget_4_chart"></div>
                <div class="card-spacer bg-white card-rounded flex-grow-1">
                  <div class="row m-0">
                    <div class="col px-8 p mr-8">
                      <div class="font-size-sm text-muted font-weight-bold">
                        Average Sale
                      </div>
                      <div class="font-size-h4 font-weight-bolder">$650</div>
                    </div>
                    <div class="col px-8 ">
                      <div class="font-size-sm text-muted font-weight-bold">
                        # of Orders
                      </div>
                      <div class="font-size-h4 font-weight-bolder">5,600</div>
                    </div>
                  </div>
            
                  <div class="row m-0">
                    <div class="col px-8 mr-8">
                      <div class="font-size-sm text-muted font-weight-bold">
                        Average # Orders
                      </div>
                      <div class="font-size-h4 font-weight-bolder">200</div>
                    </div>
                    <div class="col px-8 ">
                      <div class="font-size-sm text-muted font-weight-bold">
                        Percent to Goal
                      </div>
                      <div class="font-size-h4 font-weight-bolder">37%</div>
                    </div>
                  </div>
                </div>
               

                <template #actions>
                  <v-icon class="mr-1" small>
                    mdi-clock-outline
                  </v-icon>
                  <span class="caption grey--text font-weight-light"
                    >Updated 26 minutes ago</span
                  >
                </template>
              </base-material-chart-card>
            </div>

            <div class="col-xl-4">
              <v-card>
                <v-card-title> Top Sales </v-card-title>
                <v-row>
                  <v-col cols="12" md="12" class="mt-10">
                    <v-simple-table class="ml-2">
                      <tbody>
                        <tr v-for="(sale, i) in sales" :key="i">
                          <td>
                            <v-img :src="sale.flag" width="18" />
                          </td>
                          <td v-text="sale.country" />
                          <td v-text="sale.salesInM" />
                          <td
                            v-text="
                              ((sale.salesInM / totalSales) * 100).toFixed(2) +
                                '%'
                            "
                          />
                        </tr>
                      </tbody>
                    </v-simple-table>
                  </v-col>
                </v-row>
              </v-card>
            </div>

            <div class="col-xl-4">
              <v-card>
                <v-card-title> Bottom Sales </v-card-title>
                <v-row>
                  <v-col cols="12" md="12" class="mt-10">
                    <v-simple-table class="ml-2">
                      <tbody>
                        <tr v-for="(sale, i) in sales" :key="i">
                          <td>
                            <v-img :src="sale.flag" width="18" />
                          </td>
                          <td v-text="sale.country" />
                          <td v-text="sale.salesInM" />
                          <td
                            v-text="
                              ((sale.salesInM / totalSales) * 100).toFixed(2) +
                                '%'
                            "
                          />
                        </tr>
                      </tbody>
                    </v-simple-table>
                  </v-col>
                </v-row>
              </v-card>
            </div>
          </div> -->

          <div>
            <b-tabs pills card>
              <b-tab title="Card View" active>
                <partnerCards> </partnerCards>
              </b-tab>
              <b-tab title="List View"
                ><b-card-text>
                  <b-card>
                    <partnerList> </partnerList>
                  </b-card> </b-card-text
              ></b-tab>
            </b-tabs>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import partnerCards from "../../../components/crm/Partner-cards.vue";
import partnerList from "../../../components/crm/Partner-list.vue";

export default {
  components: {
    partnerCards,
    partnerList,
  },

  data() {
    return {
      countryData: {
        US: 2920,
        DE: 1390,
        AU: 760,
        GB: 690,
        RO: 600,
        BR: 550,
      },
      dailySalesChart: {
        data: {
          labels: ["M", "T", "W", "T", "F", "S", "S"],
          series: [[12, 17, 7, 17, 23, 18, 38]],
        },
        options: {
          lineSmooth: this.$chartist.Interpolation.cardinal({
            tension: 0,
          }),
          low: 0,
          high: 50, // creative tim: we recommend you to set the high sa the biggest value + something for a better look
          chartPadding: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
          },
        },
      },
      dataCompletedTasksChart: {
        data: {
          labels: ["12am", "3pm", "6pm", "9pm", "12pm", "3am", "6am", "9am"],
          series: [[230, 750, 450, 300, 280, 240, 200, 190]],
        },
        options: {
          lineSmooth: this.$chartist.Interpolation.cardinal({
            tension: 0,
          }),
          low: 0,
          high: 1000, // creative tim: we recommend you to set the high sa the biggest value + something for a better look
          chartPadding: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
          },
        },
      },
      emailsSubscriptionChart: {
        data: {
          labels: [
            "Ja",
            "Fe",
            "Ma",
            "Ap",
            "Mai",
            "Ju",
            "Jul",
            "Au",
            "Se",
            "Oc",
            "No",
            "De",
          ],
          series: [
            [542, 443, 320, 780, 553, 453, 326, 434, 568, 610, 756, 895],
          ],
        },
        options: {
          axisX: {
            showGrid: false,
          },
          low: 0,
          high: 1000,
          chartPadding: {
            top: 0,
            right: 5,
            bottom: 0,
            left: 0,
          },
        },
        responsiveOptions: [
          [
            "screen and (max-width: 640px)",
            {
              seriesBarDistance: 5,
              axisX: {
                labelInterpolationFnc: function(value) {
                  return value[0];
                },
              },
            },
          ],
        ],
      },
      headers: [
        {
          sortable: false,
          text: "ID",
          value: "id",
        },
        {
          sortable: false,
          text: "Name",
          value: "name",
        },
        {
          sortable: false,
          text: "Salary",
          value: "salary",
          align: "right",
        },
        {
          sortable: false,
          text: "Country",
          value: "country",
          align: "right",
        },
        {
          sortable: false,
          text: "City",
          value: "city",
          align: "right",
        },
      ],
      items: [
        {
          id: 1,
          name: "Dakota Rice",
          country: "Niger",
          city: "Oud-Tunrhout",
          salary: "$35,738",
        },
        {
          id: 2,
          name: "Minerva Hooper",
          country: "Curaçao",
          city: "Sinaai-Waas",
          salary: "$23,738",
        },
        {
          id: 3,
          name: "Sage Rodriguez",
          country: "Netherlands",
          city: "Overland Park",
          salary: "$56,142",
        },
        {
          id: 4,
          name: "Philip Chanley",
          country: "Korea, South",
          city: "Gloucester",
          salary: "$38,735",
        },
        {
          id: 5,
          name: "Doris Greene",
          country: "Malawi",
          city: "Feldkirchen in Kārnten",
          salary: "$63,542",
        },
      ],
      sales: [
        {
          country: "USA",
          flag:
            "https://demos.creative-tim.com/vue-material-dashboard-pro/img/flags/US.png",
          salesInM: 2920,
        },
        {
          country: "Germany",
          flag:
            "https://demos.creative-tim.com/vue-material-dashboard-pro/img/flags/DE.png",
          salesInM: 1300,
        },
        {
          country: "Australia",
          flag:
            "https://demos.creative-tim.com/vue-material-dashboard-pro/img/flags/AU.png",
          salesInM: 760,
        },
        {
          country: "United Kingdom",
          flag:
            "https://demos.creative-tim.com/vue-material-dashboard-pro/img/flags/GB.png",
          salesInM: 690,
        },
        {
          country: "Romania",
          flag:
            "https://demos.creative-tim.com/vue-material-dashboard-pro/img/flags/RO.png",
          salesInM: 600,
        },
        {
          country: "Brasil",
          flag:
            "https://demos.creative-tim.com/vue-material-dashboard-pro/img/flags/BR.png",
          salesInM: 550,
        },
      ],
      tabs: 0,
      list: {
        0: false,
        1: false,
        2: false,
      },
    };
  },
  computed: {
    totalSales() {
      return this.sales.reduce((acc, val) => acc + val.salesInM, 0);
    },
  },
};
</script>
