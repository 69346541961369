<template>
  <!--begin::Content-->
  <div id="kt_content" class="content d-flex flex-column flex-column-fluid">
    <!--begin::Entry-->
    <div class="d-flex flex-column-fluid">
      <!--begin::Container-->
      <div class="container">
        <div class="row">
          <div class="col-xl-3">
            <label>Search by Keyword</label>
            <div
              class="input-group input-group-sm input-group-solid max-w-175px"
            >
              <input
                v-model="search"
                type="text"
                class="form-control pl-4"
                placeholder="Search..."
              />
              <div class="input-group-append">
                <span class="input-group-text">
                  <span class="svg-icon svg-icon-md">
                    <!--begin::Svg Icon | path:assets/media/svg/icons/General/Search.svg-->
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      width="24px"
                      height="24px"
                      viewBox="0 0 24 24"
                      version="1.1"
                    >
                      <g
                        stroke="none"
                        stroke-width="1"
                        fill="none"
                        fill-rule="evenodd"
                      >
                        <rect x="0" y="0" width="24" height="24"></rect>
                        <path
                          d="M14.2928932,16.7071068 C13.9023689,16.3165825 13.9023689,15.6834175 14.2928932,15.2928932 C14.6834175,14.9023689 15.3165825,14.9023689 15.7071068,15.2928932 L19.7071068,19.2928932 C20.0976311,19.6834175 20.0976311,20.3165825 19.7071068,20.7071068 C19.3165825,21.0976311 18.6834175,21.0976311 18.2928932,20.7071068 L14.2928932,16.7071068 Z"
                          fill="#000000"
                          fill-rule="nonzero"
                          opacity="0.3"
                        ></path>
                        <path
                          d="M11,16 C13.7614237,16 16,13.7614237 16,11 C16,8.23857625 13.7614237,6 11,6 C8.23857625,6 6,8.23857625 6,11 C6,13.7614237 8.23857625,16 11,16 Z M11,18 C7.13400675,18 4,14.8659932 4,11 C4,7.13400675 7.13400675,4 11,4 C14.8659932,4 18,7.13400675 18,11 C18,14.8659932 14.8659932,18 11,18 Z"
                          fill="#000000"
                          fill-rule="nonzero"
                        ></path>
                      </g>
                    </svg>
                    <!--end::Svg Icon-->
                  </span>
                </span>
              </div>
            </div>
          </div>

          <div class="col-xl-3">
            <div class="form-group">
              <label>Sort by Location</label>
              <div></div>
              <b-form-select
                v-model="location"
                :options="stateOptions"
              ></b-form-select>
              <!-- <select class="custom-select form-control" v-model="location">
                                                            <option value="" selected="selected"> All</option>
                                                            <option value="AL">Alabama</option>
                                                            <option value="IO">Iowa</option>
                                                            <option value="NY">New York</option>
                                                            <option value="DL">Delaware</option>
                                                        </select> -->
            </div>
          </div>

          <div class="col-xl-2">
            <div class="form-group">
              <label>Company Type</label>
              <div></div>
              <select v-model="companyType" class="custom-select form-control">
                <option :value="undefined" selected="selected">All</option>
                <option value="brick">Brick and Mortar</option>
                <option value="onlineonly">Online Only</option>
                <option value="localonline">Local & Online</option>
              </select>
            </div>
          </div>

          <div class="col-xl-2">
            <div class="form-group">
              <label>Partner Rating</label>
              <div></div>
              <select v-model="vrating" class="custom-select form-control">
                <option value="0" selected="selected">All</option>
                <option value="5">5 Star</option>
                <option value="4">4 Star and above</option>
                <option value="3">3 Star and above</option>
                <option value="2">2 Star and above</option>
                <option value="1">1 Star and above</option>
              </select>
            </div>
          </div>
        </div>

        <!--begin::Row-->
        <div class="row">
          <!--begin::Col-->
          <div
            v-for="(partner, index) in partnerList"
            :key="index"
            class="col-xl-3 col-lg-6 col-md-6 col-sm-6"
          >
            <md-card class="bg-white h-100">
              <md-card-content>
                <h5 class="card-category card-category-social">
                  <i class="far fa-address-card" />
                  {{ partner.seller_company.business_profile.company_name }}
                </h5>
                <h4 class="card-title"></h4>
                <p
                  v-if="crm_quick_note"
                  class="card-description"
                  style=" overflow: hidden;
                  ext-overflow: ellipsis;
                  display: -webkit-box;
                 -webkit-line-clamp: 3; 
                 -webkit-box-orient: vertical;"
                >
                  {{ partner.crm.crm_quick_note }}
                </p>
                <div class="mb-7">
                  <div
                    class="d-flex justify-content-between align-items-center"
                  >
                    <span class="text-dark-75 font-weight-bolder mr-2"
                      >Order Total</span
                    >
                    <a href="#" class="text-muted text-hover-primary"
                      >$427,500.25</a
                    >
                  </div>
                  <div
                    class="d-flex justify-content-between align-items-center"
                  >
                    <span class="text-dark-75 font-weight-bolder mr-2"
                      >Email:</span
                    >
                    <a href="#" class="text-muted text-hover-primary">{{
                      partner.seller_company.business_profile.email
                    }}</a>
                  </div>
                  <div
                    class="d-flex justify-content-between align-items-cente my-2"
                  >
                    <span class="text-dark-75 font-weight-bolder mr-2"
                      >Phone:</span
                    >
                    <a href="#" class="text-muted text-hover-primary">{{
                      partner.seller_company.business_profile.phone
                    }}</a>
                  </div>
                  <div
                    class="d-flex justify-content-between align-items-center"
                  >
                    <span class="text-dark-75 font-weight-bolder mr-2"
                      >Location:</span
                    >
                    <span class="text-muted font-weight-bold"
                      >{{ partner.seller_company.business_profile.city }},
                      {{ partner.seller_company.business_profile.state }}</span
                    >
                  </div>
                </div>

                <div
                  class="card-stats text-center"
                  style=" position:absolute; bottom:0; "
                >
                  <a
                    class="btn btn-block btn-sm btn-light-primary font-weight-bolder text-uppercase py-4"
                    @click="
                      $router.push(
                        '/crm/partner-detail/' + partner.seller_company.id
                      )
                    "
                    >View Dealer</a
                  >
                </div>
              </md-card-content>
            </md-card>

            <!--end::Card-->
          </div>
          <!--end::Col-->
        </div>
        <!--end::Row-->
        <!--begin::Pagination-->
        <div
          class="d-flex justify-content-between align-items-center flex-wrap"
        >
          <div class="d-flex flex-wrap mr-3 mt-4">
            <b-pagination
              v-model="currentPage"
              :total-rows="partnerCount"
              :per-page="limit"
              style="margin: 0 auto;"
            ></b-pagination>
          </div>
          <div class="d-flex align-items-center">
            <select
              v-model="limit"
              class="form-control form-control-sm text-primary font-weight-bold mr-4 border-0 bg-light-primary"
              style="width: 75px;"
            >
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="30">30</option>
            </select>
            <span class="text-muted"
              >Displaying
              <template v-if="parseInt(limit) < parseInt(partnerCount)">
                {{ limit }}
              </template>
              <template v-else>
                {{ partnerCount }}
              </template>
              of {{ partnerCount }} records</span
            >
          </div>
        </div>
        <!--end::Pagination-->
      </div>
      <!--end::Container-->
    </div>
    <!--end::Entry-->
  </div>
  <!--end::Content-->
</template>

<script>
import { mapActions } from 'vuex'
import GetPartnerCard from '@/graphql/queries/GetPartnerCard.gql'
export default {
  filters: {
    filter_money: function(value) {
      if (value == null || value == undefined) return '0.00'
      else return value.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    },
  },
  data() {
    return {
      search: '',
      location: null,
      companyType: undefined,
      vrating: 0,
      partnerList: [],
      limit: 10,
      currentPage: 1,
      partnerCount: 0,
      stateOptions: [
        {
          value: null,
          text: 'All',
        },
        {
          value: 'AL',
          text: 'AL',
        },
        {
          value: 'AK',
          text: 'AK',
        },
        {
          value: 'AZ',
          text: 'AZ',
        },
        {
          value: 'AR',
          text: 'AR',
        },
        {
          value: 'CA',
          text: 'CA',
        },
        {
          value: 'CO',
          text: 'CO',
        },
        {
          value: 'CT',
          text: 'CT',
        },
        {
          value: 'DE',
          text: 'DE',
        },
        {
          value: 'FL',
          text: 'FL',
        },
        {
          value: 'GA',
          text: 'GA',
        },
        {
          value: 'HI',
          text: 'HI',
        },
        {
          value: 'ID',
          text: 'ID',
        },
        {
          value: 'IL',
          text: 'IL',
        },
        {
          value: 'IN',
          text: 'IN',
        },
        {
          value: 'IA',
          text: 'IA',
        },
        {
          value: 'KS',
          text: 'KS',
        },
        {
          value: 'KY',
          text: 'KY',
        },
        {
          value: 'LA',
          text: 'LA',
        },
        {
          value: 'ME',
          text: 'ME',
        },
        {
          value: 'MD',
          text: 'MD',
        },
        {
          value: 'MA',
          text: 'MA',
        },
        {
          value: 'MI',
          text: 'MI',
        },
        {
          value: 'MN',
          text: 'MN',
        },
        {
          value: 'MS',
          text: 'MS',
        },
        {
          value: 'MO',
          text: 'MO',
        },
        {
          value: 'MT',
          text: 'MT',
        },
        {
          value: 'NE',
          text: 'NE',
        },
        {
          value: 'NV',
          text: 'NV',
        },
        {
          value: 'NH',
          text: 'NH',
        },
        {
          value: 'NJ',
          text: 'NJ',
        },
        {
          value: 'NM',
          text: 'NM',
        },
        {
          value: 'NY',
          text: 'NY',
        },
        {
          value: 'NC',
          text: 'NC',
        },
        {
          value: 'ND',
          text: 'ND',
        },
        {
          value: 'OH',
          text: 'OH',
        },
        {
          value: 'OK',
          text: 'OK',
        },
        {
          value: 'OR',
          text: 'OR',
        },
        {
          value: 'OK',
          text: 'OK',
        },
        {
          value: 'OR',
          text: 'OR',
        },
        {
          value: 'PA',
          text: 'PA',
        },
        {
          value: 'RI',
          text: 'RI',
        },
        {
          value: 'SC',
          text: 'SC',
        },
        {
          value: 'SD',
          text: 'SD',
        },
        {
          value: 'TN',
          text: 'TN',
        },
        {
          value: 'TX',
          text: 'TX',
        },
        {
          value: 'UT',
          text: 'UT',
        },
        {
          value: 'VT',
          text: 'VT',
        },
        {
          value: 'VA',
          text: 'VA',
        },
        {
          value: 'WA',
          text: 'WA',
        },
        {
          value: 'WV',
          text: 'WV',
        },
        {
          value: 'WI',
          text: 'WI',
        },
        {
          value: 'WY',
          text: 'WY',
        },
      ],
    }
  },
  apollo: {
    partnerList: {
      query: GetPartnerCard,
      fetchPolicy: 'no-cache',
      variables() {
        return {
          supplier_company: this.$store.state.auth.user.company_relation,
          limit: parseInt(this.limit),
          start: (this.currentPage - 1) * this.limit,
          search: this.search,
          // doesn't include state if location is null
          state: this.location || undefined,
          rating: parseInt(this.vrating),
          type: this.companyType,
        }
      },
      skip() {
        return (
          !this.$store.state.auth.user ||
          this.$store.state.auth.user.company_relation === null ||
          this.$store.state.auth.user.company_relation === undefined
        )
      },
    },
  },

  watch: {
    search: function() {
      this.getPartnerCount()
    },
    location: function() {
      this.getPartnerCount()
    },
    companyType: function() {
      this.getPartnerCount()
    },
    vrating: function() {
      this.getPartnerCount()
    },
  },
  mounted() {
    setTimeout(() => {
      this.getPartnerCount()
    }, 1000)
  },
  methods: {
    ...mapActions(['getApplicationTotalCount']),
    getPartnerCount() {
      this.getApplicationTotalCount({
        params:
          'status=approved&supplier_company=' +
          this.$store.state.auth.user.company_relation +
          '&seller_company.business_profile.search_content_contains=' +
          this.search +
          '&seller_company.business_profile.state_contains=' +
          this.location +
          '&seller_company.business_profile.rating_gte=' +
          this.vrating +
          '&seller_company.business_profile.type_contains=' +
          this.companyType,
      }).then((res) => {
        this.partnerCount = res.data
      })
    },
  },
}
</script>
